:root {
  --fade-in-time: 3000ms;
  --fade-out-time: 4000ms;
}

.step-block-enter .UP_formContainer,
.step-block-enter .UP_contentContainer {
  opacity: 0;
}
.step-block-enter-done .UP_formContainer,
.step-block-enter-done .UP_contentContainer {
  opacity: 1;
}
.step-block-exit-done .UP_formContainer,
.step-block-exit-done .UP_contentContainer {
  opacity: 0;
}
.step-block-enter-active .UP_formContainer,
.step-block-enter-active .UP_contentContainer {
  opacity: 1;
  transition: opacity var(--fade-in-time) ease-in-out, transform var(--fade-in-time) ease-in-out;
  -webkit-transition: opacity var(--fade-in-time) ease-in-out,
    transform var(--fade-in-time) ease-in-out;
  -moz-transition: opacity var(--fade-in-time) ease-in-out,
    transform var(--fade-in-time) ease-in-out;
  -o-transition: opacity var(--fade-in-time) ease-in-out, transform var(--fade-in-time) ease-in-out;
}
.step-block-exit .UP_formContainer,
.step-block-exit .UP_contentContainer {
  opacity: 1;
}
.step-block-exit-active .UP_formContainer,
.step-block-exit-active .UP_contentContainer {
  opacity: 0;
  transition: opacity var(--fade-out-time) ease-in-out, transform var(--fade-out-time) ease-in-out;
  -webkit-transition: opacity var(--fade-out-time) ease-in-out,
    transform var(--fade-out-time) ease-in-out;
  -moz-transition: opacity var(--fade-out-time) ease-in-out,
    transform var(--fade-out-time) ease-in-out;
  -o-transition: opacity var(--fade-out-time) ease-in-out,
    transform var(--fade-out-time) ease-in-out;
}

h1,
h2,
img {
  position: relative;
}
