body {
  margin: 0px;
}

#step .UP_backButtonContainer {
  visibility: hidden;
}

#step .UP_waterMark {
  visibility: hidden;
}

.flags[data-uf-ui-is-standalone] .UP_dismissContainer {
  visibility: hidden;
}

.flags[data-uf-ui-has-navigate-back] .UP_backButtonContainer {
  visibility: initial !important;
}

.flags[data-uf-ui-has-watermark] .UP_waterMark {
  visibility: initial !important;
}

#step .fullpage {
  width: 100%;
  height: 100vh;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flags[data-uf-ui-busy] .light .button.primary {
  color: var(--light-primary-button-background-color);
}

.flags[data-uf-ui-busy] .light .button.primary:hover {
  color: var(--light-primary-button-background-color-hover);
}

.flags[data-uf-ui-busy] .dark .button.primary {
  color: var(--dark-primary-button-background-color);
}

.flags[data-uf-ui-busy] .dark .button.primary:hover {
  color: var(--dark-primary-button-background-color-hover);
}

.flags[data-uf-ui-busy] .light .button.secondary {
  color: var(--light-secondary-button-background-color);
}

.flags[data-uf-ui-busy] .light .button.secondary:hover {
  color: var(--light-secondary-button-background-color-hover);
}

.flags[data-uf-ui-busy] .dark .button.secondary {
  color: var(--dark-secondary-button-background-color);
}

.flags[data-uf-ui-busy] .dark .button.secondary:hover {
  color: var(--dark-secondary-button-background-color-hover);
}

.flags[data-uf-ui-busy] .button .loader {
  display: inline-block;
}

.flags[data-uf-ui-busy] .card-group:not([multiselect='true']) .card:before {
  display: block;
  content: '';
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  border: 3px solid;
  border-color: var(--light-primary-button-font-color) var(--light-primary-button-font-color)
    transparent;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

.flags[data-uf-ui-busy] .card-group:not([multiselect='true']) .card:after {
  content: ' ';
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
}

.flags[data-uf-ui-busy] .light .button.seconday {
  border-color: var(--light-secondary-button-background-color)
    var(--light-secondary-button-background-color) transparent;
}

.flags[data-uf-ui-busy] .dark .button.primary {
  border-color: var(--dark-primary-button-font-color) var(--dark-primary-button-font-color)
    transparent;
}

.flags[data-uf-ui-busy] .dark .button.seconday {
  border-color: var(--dark-secondary-button-background-color)
    var(--dark-secondary-button-background-color) transparent;
}
